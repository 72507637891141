import React from 'react'

const AboutFive = () => {
	return (
		<div className="lg:max-w-[1366px] m-auto">
    <div className="relative z-5">
        <img src="../images/aboutPage/about51.png" alt="Pozycja jogi" />
    </div>
</div>

	)
}

export default AboutFive
